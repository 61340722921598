import { Button } from 'glints-aries';
import styled from 'styled-components';

export const ButtonBlue = styled(Button).attrs({
  variant: 'yellow',
  small: true,
})`
  grid-area: hiring-button;
  margin-right: 16px;
  margin-top: 16px;

  @media (min-width: 640px) {
    margin-right: 0;
    margin-top: 0;
  }
  @media (min-width: 992px) {
    max-width: 320px;
  }

  ::before {
    background-color: #ec2728;
  }
  a {
    color: #fff;
    background-color: #017eb7;
    text-align: center;
    font-family:
      Noto Sans,
      sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.25px;
    text-transform: none;
    padding: 12px 24px;
  }
  span {
    display: flex;
    align-items: center;
  }
`;
