import React from 'react';

import { ButtonBlue } from './AriesButtonBlue.sc';

type BuilderProps = {
  text: string;
  link: string;
};
const AriesButtonBlue = (props: BuilderProps) => {
  const { text, link } = props;

  return (
    <ButtonBlue
      tag="a"
      // @ts-ignore
      href={`${link}`}
    >
      {text}
    </ButtonBlue>
  );
};

export default AriesButtonBlue;
